.Radio-group {
  width: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
}
.radio-selections-alerts-group {
  margin-left: 1rem;
}

.Alerts-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15vh;
  width: 30rem;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.945);
  margin-top: 3rem;
  box-shadow: 2px 2px 10px 2px #888888;
}
.alert-sub-box {
  /* display: flex; */
  /* justify-content:flex-start; */
  height: 1vh;
  width: 30rem;
  border-radius: 0.75rem;
  background-color: rgb(17, 207, 27);
  /* margin-top: 3rem; */
}
.alert-content {
  margin-left: 5px;
  font-family: Open Sans, Semibold;
}

.watch-list-alerts {
  cursor: pointer;
  flex-direction: column;
  text-decoration: underline;
  font-size: 1rem;
}

.favorite-icon {
  max-width: 20px;
}

.alerts-watchlist-favicon {
  color: #6e90e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alerts-timestamp {
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin: 10px;
}
.radio-btn-line {
  display: flex;
  justify-content: space-between;
}

.alert-head {
  font-weight: 800;
  font-size: 16px;
}

.alert-subHead {
  font-size: 12px;
}
