.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.barsButton{
  justify-content: flex-start;
   margin: 0.2rem;
  cursor: pointer;

}

.right{
  justify-content: flex-end;
  margin: 0.2rem;
  cursor: pointer;
}
