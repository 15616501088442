/* .MainStructureWrapper { */
  /* display: flex;
  height: 100vh; */
/* } */
/* display: none; */

/* flex: 0 0 30%; */
/* .sideBar {
  flex: 1;
  display: none;

  
  
} */

.MainView {
  flex: 1;
  
}

/* @media screen and (min-width: 768px) {
  .sideBar {
    flex: 0 0 20%;
    max-width: 300px;
  }
} */

 @media screen and (max-width: 768px) {
  .sideBar {
    flex: 0 0 20%;
  }

  .right-view-tab{
    border-radius: 0.5rem;
    width: 2rem;
    /* padding: 0.5rem 2rem;
    text-align: center;
    box-shadow: 0.2rem 0.2rem 1rem -0.3rem rgba(0, 0, 0, 0.5);
    font-weight: 800;

    margin-right: -1rem;  */
  }
  /* .MainStructureWrapper {
    display: flex;
    flex-direction: column;
  } */

} 
/* @media screen and (max-width: 479px) {
  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
   */
  /* .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  } */
  
  /* .sidebar a:hover {
    color: #f1f1f1;
  } */
  
  /* .sidebar .closeNav {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  } 
  
  .openNav {
    cursor: pointer;
    color: black;
    padding: 10px 15px;
    
    
  } */
  
  /* .openbtn:hover {
    background-color: #444;
  } */

  
/* } */