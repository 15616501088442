/* .stock-sltr-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
}

.stock-sltr-arrow-btn {
  border: 1px solid #71cb73;
  background-color: #71cb73;
  color: white;
  border-radius: 1rem;
  width: 3rem;
  cursor: pointer;
}
  .barsButton{
    font-size: 25px;
    margin: 80px;
    cursor: pointer;

    display: flex;
    position: absolute;
  }
   
}

 */

.sidebar {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgb(252, 255, 254);
  overflow-x: hidden;
  /* transition: 0.1s; */
}
.stock-sltr-submit {
  justify-content: flex-end;
  padding: 1rem;
  display: flex;
}
  .stock-sltr-arrow-btn {
    border: 1px solid #00B500;
    background-color: #00B500;
    color: white;
    border-radius: 1.563rem;
    width: 3rem;
    cursor: pointer;
    padding: 0 0.5rem;
}

  /* padding-top: 60px; */
  /* width: 100px;
  height: 100px;
  background-color: red; */




.sidebaropen{
  width: 325px;
}