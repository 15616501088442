.right-view-tab {
  border-radius: 2rem;
  width: 8rem;
  padding: 0.5rem 2rem;
  text-align: center;
  box-shadow: 0.2rem 0.2rem 1rem -0.3rem rgba(0, 0, 0, 0.5);
  font-weight: 800;
  /* overflow-x: auto; Allow horizontal scrolling */
  min-height: 2rem;
  
  /* overflow-wrap: break-word; */

  margin-right: -1rem; /* Overlaps each tab */
  
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  /* .sideBar {
    flex: 0 0 20%;
  } */

  .right-view-tab{
    border-radius: 0.8rem;
    width: 0.5rem;
    /* padding: 0.4rem 2rem; */
    font-size: 10px;
    margin-right: -0.5rem;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* overflow-x: auto; */
    /* flex-wrap: wrap; */
    /* text-wrap: balance; */
  /* white-space: nowrap; Prevent wrapping of tab content */
    overflow:hidden;
    text-overflow:ellipsis;
}

}