/* 
.Design-Portfolio{
    padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.1rem;
}


  
  .investment-amount, .Risk-Beta, .Holding-period, .Holding-period-Returns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
 
  
  input[type="number"] {
    flex: 2;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
   */
 
  
 
  /* Responsive adjustments */
  /* @media (max-width: 768px) {
    .investment-amount, .Risk-Beta, .Holding-period, .Holding-period-Returns {
      flex-direction: row;
      display: flex;
      align-items: flex-start;
      
    }
  
    label {
      margin-bottom: 0.5rem;
      margin-right: 0;
    }
  } */



.Design-Portfolio {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 5rem;
    
   
  }
  
  .investment-amount, 
  .Risk-Beta, 
  .Holding-period, 
  .Holding-period-Returns {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .investment-amount span, 
  .Risk-Beta span, 
  .Holding-period span, 
  .Holding-period-Returns span {
    width: 200px;
    font-weight:400;
  }
  
  .data-period-sidebar-number,
  .investment-amount input[type="text"], 
  .Risk-Beta input[type="text"], 
  .Holding-period input[type="text"], 
  .Holding-period-Returns input[type="text"] {
    width: 8rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  input[type="text"]:focus {
    outline: none;
    border-color: #0c0c0c;
    box-shadow: 0 0 5px rgba(17, 17, 17, 0.5);
  }
  
  h1 {
    
    margin-bottom: 2rem;
  }

  .arrow-btn{
    border: 1px solid #00B500;
    background-color: #00B500;
    color: white;
    border-radius: 1.563rem;
    width: 3rem;
    cursor: pointer;
    padding: 0 0.5rem;
   
  }
  .close-btn{
    border: 1px solid #df302a;
    background-color: #df302a;
    color: white;
    border-radius: 1.563rem;
    width: 3rem;
    cursor: pointer;
    padding: 0 0.5rem;
  }

  /* .button{
    justify-content:center;
    padding: 1rem;
    display: flex;
  } */
  .btn-design-port{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  


  @media (max-width: 768px) {
    .Design-Portfolio {
      margin: 0rem;
      gap: 1rem;
    }
  
    .investment-amount, 
    .Risk-Beta, 
    .Holding-period, 
    .Holding-period-Returns {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 0.5rem;
      font-size: 15px;
    }
  
    .investment-amount span, 
    .Risk-Beta span, 
    .Holding-period span, 
    .Holding-period-Returns span {
      width: 100%;
      font-size: 0.875rem;
      display: flex;
      align-items:flex-start;
      justify-content: center;

    }
  
    .data-period-sidebar-number,
    .investment-amount input[type="text"], 
    .Risk-Beta input[type="text"], 
    .Holding-period input[type="text"], 
    .Holding-period-Returns input[type="text"] {
        /* width: 8rem; */
        /* padding: 0.5rem; */
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
        box-sizing: border-box;
    }
  
    .btn-design-port {
      flex-direction: row;
      gap: 0.5rem;
    }
  }