.watchlist-listnames {
  font-family: Open Sans, Extrabold;
  font-size: 18px;
  font-weight: 900;
  margin: 20px;
}

.entries-per-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.entries-search {
  display: flex;
  align-items: center;
  border: 1px solid #ccc; /* Optional: Add border to the input container */
  border-radius: 4px; /* Optional: Rounded corners */
  padding: px; /* Optional: Padding inside the container */
  background-color: #f9f9f9; /* Optional: Background color */
  justify-content: space-between;
}

.entries-search .MuiSvgIcon-root {
  margin-right: 8px; /* Space between the icon and the input field */
  color: #757575; /* Optional: Color for the search icon */
}

.entries-values {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 3px;
  font-size: 16px;
  background: none;
}

.entries-values::placeholder {
  color: #757575; /* Optional: Placeholder color */
}
