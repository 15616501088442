.dropdown-select {
  padding: 0.1rem;
  border: 0.1rem solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-select:focus {
  outline: none;
  border-color: #007bff;
}
