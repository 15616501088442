.sideBarUserAccInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.sideBarUserIcon {
  width: 2rem;
  height: 2rem;
  padding: 0rem 0.5rem 0rem 0rem;
}

.sideBarUserAcc {
  display: flex;
  flex-direction: column;
}

.sideBarUserName {
  font-weight: bold;
}

.sideBarLastLogin {
  color: #666;
  font-size: 0.8rem;
}

.sideBarUpdateProfile {
  color: #6e90e0;
  font-size: 0.8rem;
}
