.charttype-wrapper {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  margin: 1rem;
  
  flex-direction: row;
  flex-wrap: nowrap;
  
  justify-content: flex-start;
  overflow-x: auto;

  /* flex-wrap: wrap; */
}

.outer-charttype{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 632px){
  .charttype-wrapper{
    /* flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll; */
    /* display: inlne; */
/* overflow-x: auto; */

/* display: flex; */
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  /* overflow-x: auto; */
}
}