.selector-icon {
  border: 0.1rem solid #eaeae3;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 0.5rem;
  margin: 0.1rem;

  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-weight: 700;
  font-size: 0.7rem;
  cursor: pointer;

  :hover& {
    background-color: #f0f0f0;
  }
}

@media screen and (max-width: 768px) {
.selector-icon{
  min-width: 4rem;
}
}