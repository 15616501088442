.stock-selector-main {
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.stock-sltr-country-main {
  display: flex;
  justify-content: space-between;
}

.stock-sltr-stock-main {
  display: flex;
  justify-content: space-between;
}

.stock-sltr-data-period-main {
  display: flex;
  justify-content: space-between;
}

.data-period-sidebar {
  display: flex;
  align-items: center;
}

.data-period-sidebar-number {
  width: 0.01rem;
  height: 0.5rem;
  border: 0.1rem solid #ccc;
  border-radius: 0.2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.stock-sltr-date-picker {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 0.2rem;
}

.stock-sltr-date-picker input[type="date"],
.stock-sltr-date-picker input[type="date"]:focus,
.stock-sltr-date-picker input[type="date"].no-border {
  /* border: 1px solid #ffffff; */
  border: none;
}

.stock-sltr-from {
  align-self: flex-end;
}

.stock-sltr-to {
  align-self: flex-end;
}
