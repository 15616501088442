/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
} */

.main{
  text-align: center;
  justify-content: center;
  display: flex;
  object-fit: cover;
  
  /* padding: 90px 0 90px 0; */
  
}

.sub-main{
  display: flex;
  justify-content: center;
  /* top: 8.5rem; */ 
  /* left: 42.313rem; */
  height: 80vh;
  width: 25rem;
  /* box-shadow: 11px 12px 13px 12px rgb(287 287 287);  */
  padding-top: 1.875rem;
  border-radius: 0.75rem;
  background-color: white;
  margin-top: 3rem;
  
  
}

/* #root{
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.img{
  padding-top: 0.75rem;
  justify-content: center;
  display: flex;
}

.container-imgs{
  background-color: rgba(223, 221, 221, 0);
  /* border-radius: 150px; */
  align-items: center;
  display: flex;
  top: 10.938rem;
  left: 46.438rem;
  /* width: 42rem;  */
  justify-content: center;
  height: 10.313rem;
  padding-top: 2.5rem;
  /* object-fit: fill; */
}

.logo{
  /* padding-top: 0.1rem; */
  align-items: center;
  width: 20.25rem;
  height: 4.938rem;
  margin-bottom: 6.5rem;
}



.password{
  padding-top: 1.25rem;
} 

.button{
  height: 1.938rem;
  border-radius: 1.563rem;
  background-color: #00b500;
  padding: 0 0.5rem;
  color: #faf2f2fd;
  border: none;
  cursor: pointer;
  
}



.name{
  /* border-radius: 1.875rem; */
  border: none;
  width: 14.125rem;
  height: 1.2rem;
}

textarea:focus, input:focus{
  outline: none;
}

.name-div{
  padding: 5rem  ;
}
.common{
  /* margin-top: 1rem; */
  border: 0.063rem solid black;
  border-radius: 3rem;
  width: 14.75rem;
  height: 0.5rem;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  

}


.sign-in{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -6.5rem;
 
}


.submit-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.remember-me{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* padding-left: 5rem; */
  /* padding-left: 5rem;
  padding-right: 5rem; */
  flex-direction: row;
}

.RememberMe-ForgotPassword{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  margin-top: 0.25rem;
  gap: 1rem;
  font-size: 0.8rem;
  text-wrap: nowrap; 
  
}
.submit{
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
}
.create-account{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  font-size: 0.8rem;
  gap: 0.5rem;
  
}

.text{
  color: #000000;
  font-size: 3rem;
  font-weight: 700;
}

.underline{
  text-decoration: underline;
  color: #6E90E0;
  font-size: 0.8rem;
}

.forgot-password{
  cursor: pointer;
  color: #6E90E0;
  flex-direction: column;
  
}

.create{
  cursor: pointer;
}

video{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 768px) {
  .RememberMe-ForgotPassword{
    flex-direction: row;
    align-items: flex-start;
  }
}


